



















import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

@Component({
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical
  }
})
export default class EmployeeDashboard extends Vue {
  async created() {
    await this.getItems();
  }

  statisticsItems: any = [];

  async getItems() {
    const item = await this.$api.employee.maintenance.GetDashboard();

    this.statisticsItems.push({
      icon: 'TrendingUpIcon',
      color: 'info',
      statistic: item.employeeCount,
      title: 'Employee Count'
    });

    this.statisticsItems.push({
      icon: 'TrendingUpIcon',
      color: 'success',
      statistic: item.activityTracked,
      title: 'Activity Tracked'
    });
  }
}
